<template>
  <el-table-column
    :label="columnConfig.label"
    :align="columnConfig.align"
  >
    <template slot="header">
      <div class="medium-fact-column">
        <div>{{ columnConfig.label }}</div>
      </div>
    </template>


    <el-table-column
      v-for="(card, cardId) in cards"
      :key="cardId"
      :width="200"
      align="center"
      :label="cardId"
    >
      <template slot="header" slot-scope="scope">
        {{ card.user.full_name }}
      </template>
      <template v-slot="scope">
        <click-to-edit
          v-model="scope.row.weight[card.id]"
          placeholder=""
          digits-only
          @input="saveElementPropChange(scope.row, 'weight')"
        ></click-to-edit>
      </template>
    </el-table-column>
  </el-table-column>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'matrix-weight-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    isMatrix: {type: Boolean, default: true},
    columnConfig: {type: Object, required: true},
    cards: {},
  },
  data() {
    return {}
  },
  computed: {},
  methods: {

  }
}

</script>

<style>

</style>
